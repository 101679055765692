import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TextInputTypeComponent } from './formly/text-input/text-input.type';
import { TranslateService } from '@ngx-translate/core';
import { RadioInputTypeComponent } from './formly/radio-input/radio-input.type';
import { Router } from '@angular/router';
import { Service, Websocket } from 'src/app/shared/shared';
import { RegisterUserRequest } from 'src/app/shared/jsonrpc/request/registerUserRequest';
import { environment } from 'src/environments';
import { SelectInputTypeComponent } from './formly/select-input/select-input.type';
import { COUNTRY_OPTIONS } from 'src/app/shared/type/country';
import { LoadingController } from '@ionic/angular';

@Component({
  selector: 'registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent {
  protected readonly countries = COUNTRY_OPTIONS(this.translate);
  protected registrationSuccessful:boolean = false;

  protected form = new FormGroup({});
  protected model = {};
  protected fields: FormlyFieldConfig[] = [
    {
      // Use expression instead of template to allow translations
      expressions: {
        template: () => `<p class="text-subtitle1">${this.translate.instant('Voltfang.Register.Form.roleTitle')}</p>`,
      },
    },
    {
      key: 'role',
      type: RadioInputTypeComponent,
      props: {

      },
    },
    {
      key: 'role',
      type: RadioInputTypeComponent,
      className: '',
      props: {
        label: 'Radio',
        required: true,
        options: [
          {
            value: 'owner',
            label: 'Voltfang.Register.Form.Role.user',
          },
          {
            value: 'installer',
            label: 'Voltfang.Register.Form.Role.installer',
          },
        ],
      },
    },
    {
      expressions: {
        "template": () => `<p>${this.translate.instant('Voltfang.Register.Form.installerInfo')}</p>`,
        'hide': 'model.role !== "installer"',
      },
    },
    {
      expressions: {
        template: () => `<p class="text-subtitle1">${this.translate.instant('Voltfang.Register.Form.personalInfoTitle')}</p>`,
      },
    },
    {
      fieldGroup: [
        {
          fieldGroupClassName: 'display-flex gap-05',
          fieldGroup: [
            {
              className: 'flex-1',
              key: 'firstname',
              type: TextInputTypeComponent,
              props: {
                placeholder: 'Register.Form.firstname',
                required: true,
              },
            },
            {
              className: 'flex-1',
              key: 'lastname',
              type: TextInputTypeComponent,
              props: {
                placeholder: 'Register.Form.lastname',
                required: true,
              },
            },
          ],
        },
        {
          key: 'phone',
          type: TextInputTypeComponent,
          props: {
            type: 'tel',
            placeholder: 'Register.Form.phone',
            required: true,
          },
        },
        {
          key: 'email',
          type: TextInputTypeComponent,
          props: {
            type: 'email',
            placeholder: 'Register.Form.email',
            required: true,
          },
        },
        {
          expressions: {
            template: () => `<p class="text-subtitle1">${this.translate.instant('Voltfang.Register.Form.companyInfoTitle')}</p>`,
          },
        },
        {
          key: 'company',
          type: TextInputTypeComponent,
          props: {
            placeholder: 'Register.Form.companyName',
            required: true,
          },
        },
        {
          key: 'street',
          type: TextInputTypeComponent,
          props: {
            placeholder: 'Register.Form.street',
            required: true,
          },
        },
        {
          fieldGroupClassName: 'display-flex gap-05',
          fieldGroup: [
            {
              className: 'flex-1',
              key: 'zip',
              type: TextInputTypeComponent,
              props: {
                placeholder: 'Register.Form.zip',
                required: true,
              },
            },
            {
              className: 'flex-1',
              key: 'city',
              type: TextInputTypeComponent,
              props: {
                placeholder: 'Register.Form.city',
                required: true,
              },
            },
          ],
        },
        {
          key: 'country',
          type: SelectInputTypeComponent,
          defaultValue: 'de',
          props: {
            options: this.countries,
            required: true,
          },
        },
        {
          expressions: {
            template: () => `
            <p class="text-subtitle1">${this.translate.instant('Voltfang.Register.Form.credentialsTitle')}</p>
            <p>${this.translate.instant('Voltfang.Register.Form.credentialsInfo')}</p>
            `,
          },
        },
      ],
    },
    {
      fieldGroup: [
        {
          fieldGroupClassName: 'display-flex gap-05',
          fieldGroup: [
            {
              className: 'flex-1',
              key: 'password',
              type: TextInputTypeComponent,
              props: {
                type: 'password',
                placeholder: 'Register.Form.password',
                required: true,
              },
            },
            {
              className: 'flex-1',
              key: 'confirmPassword',
              type: TextInputTypeComponent,
              props: {
                type: 'password',
                placeholder: 'Register.Form.confirmPassword',
                required: true,
              },
            },
          ],
        },
      ],
    },
  ];

  private loadingSpinnerDuration = 10000;

  constructor(public translate: TranslateService, public router: Router, public service:Service, public websocket:Websocket, public loadingCtrl:LoadingController) {}

  /**
   * Validate the current form and sends the registration request.
  */
 onSubmit() {
   this.showLoading();

   if (!this.form.valid) {
     this.service.toast(this.translate.instant("Register.errors.requiredFields"), 'danger');
      this.loadingCtrl.dismiss();
      return;
    }

    const password = this.model["password"];
    const confirmPassword = this.model["confirmPassword"];

    if (password != confirmPassword) {
      this.service.toast(this.translate.instant("Register.errors.passwordNotEqual"), 'danger');
      return;
    }

    const request = new RegisterUserRequest({
      user: {
        firstname: this.model["firstname"],
        lastname: this.model["lastname"],
        phone: this.model["phone"],
        email: this.model["email"],
        company: {
          name: this.model["company"],
        },
        password: password,
        confirmPassword: confirmPassword,
        address: {
          street: this.model["street"],
          zip: this.model["zip"],
          city: this.model["city"],
          country: this.model["country"],
        },
        role: this.model["role"],
        subscribeNewsletter: false,
      },
      oem: environment.theme,
    });

    this.websocket.sendRequest(request)
      .then(() => {
        this.loadingCtrl.dismiss();
        this.registrationSuccessful = true;
      })
      .catch(reason => {
        this.loadingCtrl.dismiss();
        this.service.toast(reason.error.message, 'danger');
      });
  }

  navigateToLoginPage() {
    this.router.navigate(['/login']);
  }

  async showLoading() {
    const loading = await this.loadingCtrl.create({
      message: this.translate.instant('Voltfang.Register.inProgress'),
      duration: this.loadingSpinnerDuration,
    });

    loading.present();
  }
}
