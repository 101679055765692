// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  margin-top: auto;
  background-color: var(--ion-color-dark);
  color: var(--ion-color-light);
  padding: 2rem;
  width: 100%;
}

@media (min-width: 1200px) {
  :host {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/banner/banner.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,uCAAA;EACA,6BAAA;EACA,aAAA;EACA,WAAA;AACJ;;AAEA;EACI;IACI,aAAA;EACN;AACF","sourcesContent":[":host {\n    margin-top: auto;\n    background-color: var(--ion-color-dark);\n    color: var(--ion-color-light);\n    padding: 2rem;\n    width: 100%;\n}\n\n@media (min-width: 1200px) {\n    :host {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
