import { Directive } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Websocket } from '../../shared';

@Directive({
  selector: '[oeWebsocketStatus]',
})
export class WebsocketStatusDirective {
  private checkInterval = 1000;

  constructor(
    private loadingCtrl: LoadingController,
    private translate: TranslateService,
    private websocket: Websocket,
  ){
    this.showLoading();
  }

  private async showLoading() {
    const loading = await this.loadingCtrl.create({
      message: this.translate.instant('Index.connectionInProgress'),
    });

    setInterval(() => {
      switch (this.websocket.status) {
        case 'initial':
        case 'connecting':
          loading.message = this.translate.instant('Index.connectionInProgress');
          loading.present();
          break;
        case 'failed':
          loading.message = this.translate.instant('Index.connectionFailed', {value: 'Venma'});
          loading.present();
          break;
        default:
          // 'online' is skipped here because if the connection is online, the loading will not be shown
          // 'waiting for credentials' is skipped because then the user is redirected to the login page
          // Redirecting a user on these two websocket states is handled by the loading-screen component.
          loading.dismiss();
          break;
      }
    }, this.checkInterval);
  }
}
