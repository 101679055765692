// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-grid {
  max-width: var(--max-page-content-width);
}`, "",{"version":3,"sources":["webpack://./src/app/edge/history/history.component.scss"],"names":[],"mappings":"AAAA;EACI,wCAAA;AACJ","sourcesContent":["ion-grid {\n    max-width: var(--max-page-content-width);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
