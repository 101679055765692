import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  @Input() public text: string = '';
  @Input() public buttonText: string = '';
  @Output() public buttonClickedEvent: EventEmitter<void> = new EventEmitter<void>();

  onButtonClicked() {
    this.buttonClickedEvent.emit();
  }
}
