// @ts-strict-ignore
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AbstractHistoryChart } from 'src/app/shared/components/chart/abstracthistorychart';
import { QueryHistoricTimeseriesEnergyResponse } from 'src/app/shared/jsonrpc/response/queryHistoricTimeseriesEnergyResponse';
import { ChartAxis, HistoryUtils, YAxisTitle } from 'src/app/shared/service/utils';
import { ChannelAddress, EdgeConfig, Utils } from 'src/app/shared/shared';
import { environment } from 'src/environments'; // oEMS
import { ColorUtils } from 'src/app/shared/utils/color/color.utils';

@Component({
  selector: 'energychart',
  templateUrl: '../../../../../shared/components/chart/abstracthistorychart.html',
})
export class ChartComponent extends AbstractHistoryChart {

  public environment = environment; // oEMS

  public static getChartData(config: EdgeConfig | null, chartType: 'line' | 'bar', translate: TranslateService): HistoryUtils.ChartData {

    const isVoltfangTheme = environment.theme === 'Voltfang'; // oEMS

    const essDischargePowerString = isVoltfangTheme ? 'EssActivePower' : 'EssDischargePower'; // oEMS

    const input: HistoryUtils.InputChannel[] =
      config?.widgets.classes.reduce((arr: HistoryUtils.InputChannel[], key) => {
        const newObj = [];
        switch (key) {
          case 'Energymonitor':
          case 'Consumption':
            newObj.push({
              name: 'Consumption',
              powerChannel: new ChannelAddress('_sum', 'ConsumptionActivePower'),
              energyChannel: new ChannelAddress('_sum', 'ConsumptionActiveEnergy'),
            });
            break;
          case 'Common_Autarchy':
          case 'Grid':
            newObj.push({
              name: 'GridBuy',
              powerChannel: new ChannelAddress('_sum', 'GridActivePower'),
              energyChannel: new ChannelAddress('_sum', 'GridBuyActiveEnergy'),
              ...(chartType === 'line' && { converter: HistoryUtils.ValueConverter.NEGATIVE_AS_ZERO }),
            }, {
              name: 'GridSell',
              powerChannel: new ChannelAddress('_sum', 'GridActivePower'),
              energyChannel: new ChannelAddress('_sum', 'GridSellActiveEnergy'),
              ...(chartType === 'line' && { converter: HistoryUtils.ValueConverter.POSITIVE_AS_ZERO_AND_INVERT_NEGATIVE }),
            });
            break;
          case 'Storage':
            newObj.push({
              name: 'EssSoc',
              powerChannel: new ChannelAddress('_sum', 'EssSoc'),
            }, {
              name: 'EssCharge',
              powerChannel: new ChannelAddress('_sum', essDischargePowerString), // oEMS
              energyChannel: new ChannelAddress('_sum', 'EssDcChargeEnergy'),
            }, {
              name: 'EssDischarge',
              powerChannel: new ChannelAddress('_sum', essDischargePowerString), // oEMS
              energyChannel: new ChannelAddress('_sum', 'EssDcDischargeEnergy'),
            });
            break;
          case 'Common_Selfconsumption':
          case 'Common_Production':
            newObj.push({
              name: 'ProductionActivePower',
              powerChannel: new ChannelAddress('_sum', 'ProductionActivePower'),
              energyChannel: new ChannelAddress('_sum', 'ProductionActiveEnergy'),
            }, {
              name: 'ProductionDcActual',
              powerChannel: new ChannelAddress('_sum', 'ProductionDcActualPower'),
              energyChannel: new ChannelAddress('_sum', 'ProductionActiveEnergy'),
            });
            break;
        }

        arr.push(...newObj);
        return arr;
      }, []);

    const productionColor = ColorUtils.hexToRgb(
      getComputedStyle(document.documentElement).getPropertyValue("--ion-color-warning"),
    );
    const chargeColor = ColorUtils.hexToRgb(
      getComputedStyle(document.documentElement).getPropertyValue("--ion-color-primary"),
    );
    const dischargeColor = ColorUtils.hexToRgb(
      getComputedStyle(document.documentElement).getPropertyValue("--ion-color-discharge"),
    );
    const sellColor = ColorUtils.hexToRgb(
      getComputedStyle(document.documentElement).getPropertyValue("--ion-color-tertiary"),
    );
    const buyColor = ColorUtils.hexToRgb(
      getComputedStyle(document.documentElement).getPropertyValue("--ion-color-danger"),
    );
    const consumptionColor = ColorUtils.hexToRgb(
      getComputedStyle(document.documentElement).getPropertyValue("--ion-color-consumption"),
    );
    const socColor = ColorUtils.hexToRgb(
      getComputedStyle(document.documentElement).getPropertyValue("--ion-color-medium-shade"),
    );
    const socShadowColor = ColorUtils.rgbStringToRGBA(socColor, 0);

    return {
      input: input,
      output: (data: HistoryUtils.ChannelData) => {
        return [
          {
            name: translate.instant('General.production'),
            nameSuffix: (energyValues: QueryHistoricTimeseriesEnergyResponse) => energyValues.result.data['_sum/ProductionActiveEnergy'],
            converter: () => data['ProductionActivePower'],
            color: productionColor,
            stack: 0,
            hiddenOnInit: chartType == 'line' ? false : true,
            order: 1,
          },

          // DirectConsumption, displayed in stack 1 & 2, only one legenItem
          ...[chartType === 'bar' && {
            name: translate.instant('General.directConsumption'),
            nameSuffix: (energyValues: QueryHistoricTimeseriesEnergyResponse) => {
              return Utils.subtractSafely(energyValues.result.data['_sum/ProductionActiveEnergy'], energyValues.result.data['_sum/GridSellActiveEnergy'], energyValues.result.data['_sum/EssDcChargeEnergy']);
            },
            converter: () =>
              data['ProductionActivePower']?.map((value, index) => data['EssCharge'] ? Utils.subtractSafely(value, data['GridSell'][index], data['EssCharge'][index]) : Utils.subtractSafely(value, data['GridSell'][index])) // oEMS
                ?.map(value => HistoryUtils.ValueConverter.NEGATIVE_AS_ZERO(value)),
            color: consumptionColor,
            stack: [1, 2],
            order: 2,
          }],

          // Charge Power
          {
            name: translate.instant('General.chargePower'),
            nameSuffix: (energyValues: QueryHistoricTimeseriesEnergyResponse) => energyValues.result.data['_sum/EssDcChargeEnergy'],
            converter: () => chartType === 'line' //
              ? data['EssCharge']?.map((value, index) => {
                if (isVoltfangTheme) { // oEMS
                  return HistoryUtils.ValueConverter.POSITIVE_AS_ZERO_AND_INVERT_NEGATIVE(Utils.subtractSafely(value, data['ProductionDcActual']?.[index]));
                }
                return HistoryUtils.ValueConverter.POSITIVE_AS_ZERO_AND_INVERT_NEGATIVE(value); // oEMS USE DC Discharge instead of calculation lookin forward -> SumImpl calculates everything
              }) : data['EssCharge'],
            color: chargeColor,
            stack: 1,
            ...(chartType === 'line' && { order: 6 }),
          },

          // Discharge Power
          {
            name: translate.instant('General.dischargePower'),
            nameSuffix: (energyValues: QueryHistoricTimeseriesEnergyResponse) => energyValues.result.data['_sum/EssDcDischargeEnergy'],
            converter: () => {
              return chartType === 'line' ?
                data['EssDischarge']?.map((value, index) => {
                  if (isVoltfangTheme) { // oEMS
                    return HistoryUtils.ValueConverter.NEGATIVE_AS_ZERO(Utils.subtractSafely(value, data['ProductionDcActual']?.[index]));
                  }
                  return HistoryUtils.ValueConverter.NEGATIVE_AS_ZERO(value);
                }) : data['EssDischarge'];
            },
            color: dischargeColor,
            stack: 2,
            ...(chartType === 'line' && { order: 5 }),
          },

          // Sell to grid
          {
            name: translate.instant('General.gridSellAdvanced'),
            nameSuffix: (energyValues: QueryHistoricTimeseriesEnergyResponse) => energyValues.result.data['_sum/GridSellActiveEnergy'],
            converter: () => data['GridSell'],
            color: sellColor,
            stack: 1,
            ...(chartType === 'line' && { order: 4 }),
          },

          // Buy from Grid
          {
            name: translate.instant('General.gridBuyAdvanced'),
            nameSuffix: (energyValues: QueryHistoricTimeseriesEnergyResponse) => energyValues.result.data['_sum/GridBuyActiveEnergy'],
            converter: () => data['GridBuy'],
            color: buyColor,
            stack: 2,
            ...(chartType === 'line' && { order: 2 }),
          },

          // Consumption
          {
            name: translate.instant('General.consumption'),
            nameSuffix: (energyValues: QueryHistoricTimeseriesEnergyResponse) => energyValues.result.data['_sum/ConsumptionActiveEnergy'],
            converter: () => data['Consumption'],
            color: consumptionColor,
            stack: 3,
            hiddenOnInit: chartType == 'line' ? false : true,
            ...(chartType === 'line' && { order: 0 }),
          },
          ...[chartType === 'line' &&
          {
            name: translate.instant('General.soc'),
            converter: () => data['EssSoc']?.map(value => Utils.multiplySafely(value, 1000)),
            color: socColor,
            borderDash: [10, 10],
            yAxisId: ChartAxis.RIGHT,
            stack: 1,
            custom: {
              unit: YAxisTitle.PERCENTAGE,
            },
            customShadowColor: socShadowColor,
          }],
        ];
      },
      tooltip: {
        formatNumber: '1.0-2',
        afterTitle: (stack: string) => {
          if (stack === "1") {
            return translate.instant('General.production');
          } else if (stack === "2") {
            return translate.instant('General.consumption');
          }
          return null;
        },
      },
      yAxes: [

        // Left YAxis
        {
          unit: YAxisTitle.ENERGY,
          position: 'left',
          yAxisId: ChartAxis.LEFT,
        },

        // Right Yaxis, only shown for line-chart
        (chartType === 'line' && {
          unit: YAxisTitle.PERCENTAGE,
          customTitle: '%',
          position: 'right',
          yAxisId: ChartAxis.RIGHT,
          displayGrid: false,
        }),
      ],
    };
  }

  public override getChartData() {
    return ChartComponent.getChartData(this.config, this.chartType, this.translate);
  }

  protected override getChartHeight(): number {
    return this.service.deviceHeight / 2;
  }
}
