// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-wrapper {
  position: relative;
}

select {
  width: 100%;
  height: 3rem;
  appearance: none;
  background-color: var(--ion-color-light);
  padding-left: 1rem;
  border: 1px solid var(--ion-color-dark-tint);
  border-radius: var(--default-border-radius);
  font-family: inherit;
}

option {
  font-family: inherit;
}

.icon {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%) rotate(90deg);
  height: 2.5rem;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/app/registration/formly/select-input/select-input.type.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,gBAAA;EACA,wCAAA;EACA,kBAAA;EACA,4CAAA;EACA,2CAAA;EACA,oBAAA;AACJ;;AAEA;EACI,oBAAA;AACJ;;AAEA;EACI,qBAAA;EACA,kBAAA;EACA,QAAA;EACA,QAAA;EACA,QAAA;EACA,8CAAA;EACA,cAAA;EACA,oBAAA;AACJ","sourcesContent":[".select-wrapper {   \n    position: relative;\n}\n\nselect {\n    width: 100%;\n    height: 3rem;\n    appearance: none;\n    background-color: var(--ion-color-light);\n    padding-left: 1rem;\n    border: 1px solid var(--ion-color-dark-tint);\n    border-radius: var(--default-border-radius);\n    font-family: inherit;\n}\n\noption {\n    font-family: inherit;\n}\n\n.icon {\n    display: inline-block;\n    position: absolute;\n    right: 0;    \n    top: 50%;\n    right: 0;\n    transform: translate(-50%, -50%) rotate(90deg);\n    height: 2.5rem;\n    pointer-events: none;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
