// @ts-strict-ignore
export namespace ColorUtils {

  /**
   * Converts a rgb-string into a rgba-string
   *
   * @param color the color
   * @param opacity the opacity
   * @returns a string in rgba format
   */
  export function rgbStringToRGBA(color: string, opacity: number): string {

    if (!color) {
      return null;
    }

    return 'rgba(' + color.split('(').pop().split(')')[0] + ',' + (opacity ?? 0) + ')';
  }

  /**
   * Changes opacity of a passed rgba string
   *
   * @param color the color
   * @param opacity the opacity
   * @returns a string in rgba format
   */
  export function changeOpacityFromRGBA(color: string, opacity: number): string {

    if (!color) {
      return null;
    }

    const rgba = color.split('(').pop().split(')')[0];
    const rgb = rgba.split(',').slice(0, -1).join(',');

    return 'rgba(' + rgb + ',' + (opacity ?? 0) + ')';
  }

  /**
   * Converts a hexadecimal color string to an RGB color string.
   * @param {string} hex - The hexadecimal color string to convert.
   *                       Must be in the format "#RRGGBB".
   * @returns {string|null} The color in RGB format as a string (e.g., "rgb(255, 0, 128)"),
   *                        or null if the input is invalid.
   */
  export function hexToRgb(hex: string): string | null {
    const HEX_COLOR_REGEX = /^#[a-f\d]{6}$/i;
    if (!HEX_COLOR_REGEX.test(hex)) {
      throw new Error('Invalid hex color format. Must be #RRGGBB.');
    }

    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgb(${r}, ${g}, ${b})`;
  }
}
