import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'text-input-type',
  templateUrl: './text-input.type.html',
  styleUrls: ['./text-input.type.css'],
})
export class TextInputTypeComponent extends FieldType<FieldTypeConfig> {
  constructor(public translate: TranslateService) {
   super();
  }

  getPlaceholder():string {
    let placeholder = this.translate.instant(this.props.placeholder);
    if (this.props.required){
      placeholder += '*';
    }
    return placeholder;
  }
}
