import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { RegistrationComponent } from './registration.component';
import { TextInputTypeComponent } from './formly/text-input/text-input.type';
import { RadioInputTypeComponent } from './formly/radio-input/radio-input.type';
import { SelectInputTypeComponent } from './formly/select-input/select-input.type';

@NgModule({
  declarations: [
    RegistrationComponent,
    TextInputTypeComponent,
    RadioInputTypeComponent,
    SelectInputTypeComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    RegistrationComponent,
  ],
})
export class RegistrationModule { }
