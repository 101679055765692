// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.download-btn {
    position: absolute;
    right: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/app/edge/history/common/energy/flat/flat.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;AACf","sourcesContent":[".download-btn {\n    position: absolute;\n    right: 2rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
