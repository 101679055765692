// @ts-strict-ignore
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments';

import { Service, Websocket } from '../../shared';
import { PickDateComponent } from '../pickdate/pickdate.component';
import { StatusSingleComponent } from '../status/single/status.component';
import { FooterComponent } from '../footer/footer';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewChecked {

    @ViewChild(PickDateComponent, { static: false }) public PickDateComponent: PickDateComponent;

    public environment = environment;
    public currentPage: 'EdgeSettings' | 'Other' | 'IndexLive' | 'IndexHistory' = 'Other';
    public isSystemLogEnabled: boolean = false;

    protected displayValues: { comment: string, id: string, version: string } | null = null;

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private cdRef: ChangeDetectorRef,
        public menu: MenuController,
        public modalCtrl: ModalController,
        public router: Router,
        public service: Service,
        public websocket: Websocket,
        private route: ActivatedRoute,
        protected title: Title,
    ) { }

    ngOnInit() {
        // set inital URL
        this.updateUrl(this.router.routerState.snapshot.url);
        // update backUrl on navigation events
        this.router.events.pipe(
            takeUntil(this.ngUnsubscribe),
            filter(event => event instanceof NavigationEnd),
        ).subscribe(event => {
            window.scrollTo(0, 0);
            this.updateUrl((<NavigationEnd>event).urlAfterRedirects);
        });

        this.setEdgeTitle();
    }

    // used to prevent 'Expression has changed after it was checked' error
    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    updateUrl(url: string) {
        this.updateCurrentPage(url);
    }

    updateCurrentPage(url: string) {
        const urlArray = url.split('/');
        let file = urlArray.pop();
        if (urlArray.length >= 4) {
            file = urlArray[3];
        }
        // Enable Segment Navigation for Edge-Index-Page
        if ((file == 'history' || file == 'live') && urlArray.length == 3) {
            if (file == 'history') {
                this.currentPage = 'IndexHistory';
            } else {
                this.currentPage = 'IndexLive';
            }
        } else {
            this.currentPage = 'Other';
        }
    }

    async presentSingleStatusModal() {
        const modal = await this.modalCtrl.create({
            component: StatusSingleComponent,
        });
        return await modal.present();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    setEdgeTitle() {
        this.service.currentEdge.subscribe((edge) => {
            this.service.metadata.pipe(filter(metadata => !!metadata)).subscribe((metadata) => {
              const user = metadata.user;

              let title = environment.edgeShortName;
              if (edge) {
                this.displayValues = FooterComponent.getDisplayValues(user, edge);

                if (user.hasMultipleEdges) {
                  title += " | " + edge.id;
                }
              }

              this.title.setTitle(title);
            });
          });
    }
}
