import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.html',
  styleUrls: ['./info-modal.css'],
})

export class InfoModalComponent {
  @Input()
  public title: string = "";

  @Input()
  public info: string = "";
}
