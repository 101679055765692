// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
    --ion-toolbar-background: var(--ion-color-primary);
    --ion-toolbar-color: var(--text-color-light);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modal/info-modal/info-modal.css"],"names":[],"mappings":"AAAA;IACI,kDAAkD;IAClD,4CAA4C;AAChD","sourcesContent":[":host {\n    --ion-toolbar-background: var(--ion-color-primary);\n    --ion-toolbar-color: var(--text-color-light);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
