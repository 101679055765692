// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toolbar {
    --background: var(--ion-color-dark);
    --color: var(--text-color-light);
}

ion-item {
    text-wrap: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/header/header.component.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,gCAAgC;AACpC;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["ion-toolbar {\n    --background: var(--ion-color-dark);\n    --color: var(--text-color-light);\n}\n\nion-item {\n    text-wrap: nowrap;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
