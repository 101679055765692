import { Component } from "@angular/core";
import { AbstractFlatWidgetLine } from "../abstract-flat-widget-line";

@Component({
    selector: 'oe-flat-widget-percentagebar',
    templateUrl: './flat-widget-percentagebar.html',
    styleUrls: ['./flat-widget-percentagebar.css'],
})
export class FlatWidgetPercentagebarComponent extends AbstractFlatWidgetLine {
    public getNormalizedValue() {
        /**
         * Returns the current display value in a range from 0 to 1.
         */

        // Use this.displayValue instead of this.value because some components set the
        // displayValue directly and ignore the value property.
        return Number(this.displayValue) / 100;
    }
}
