// @ts-strict-ignore
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Service, Websocket } from '../../shared/shared';

@Component({
  selector: 'index',
  templateUrl: './loading-screen.html',
  styleUrls: ['./loading-screen.scss'],
})
export class LoadingScreenComponent implements OnInit {

  constructor(
    public service: Service,
    public websocket: Websocket,
    private router: Router,
  ) { }

  ngOnInit() {
    // TODO add websocket status observable
    const interval = setInterval(() => {
      if (this.websocket.status === 'online') {
        this.router.navigate(['/overview']);
        clearInterval(interval);
      }
      if (this.websocket.status === 'waiting for credentials') {
        this.router.navigate(['/login']);
        clearInterval(interval);
      }
    }, 1000);
  }
}
